import React from 'react';
import '../css/buttons.css';

const redirectToGoogleForms = () => {
  window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfNSCZPpI0ieE8p3tsccHMQLFG5HphU9oc8WZx4B3bzjD0FTg/viewform';
};

const ActionButtons = () => {
  return (
    <div className="action-buttons-container">
      <div className="welcome-message">
        <h2>Welcome to Our Team</h2>
        <p>Join us in making a difference and be part of something big.</p>
      </div>
      <button onClick={redirectToGoogleForms}>
        Join the Team
      </button>
      <div className="additional-info">
        <p>Learn more about our team dynamics and projects.</p>
      </div>
    </div>
  );
};

export default ActionButtons;
