import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/App.css';
import About from './js/about.js';
import Main from './js/main.js';
import MeetTheCar from "./MeetTheCar/MeetTheCar";
import Timeline from './js/timeline.js';
import Footer from './js/footer.js';
import Navbar from './navbar/navbar';
import ActionButtons from './js/buttons.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sponsor from './js/sponsor.js'
//import logo from './logo.svg';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/meet-the-car" element={<MeetTheCar />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/join" element={<ActionButtons />} />
          <Route path="/sponsors" element={<Sponsor />} />
          <Route path="*" element={<h1>Page Not Found</h1>} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
