import React from 'react';
import '../css/footer.css';
import smallLogo from '../Assets/Wordmark.png'; // Adjust the path as necessary
import '@fortawesome/fontawesome-free/css/all.min.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-branding">
          <img src={smallLogo} alt="Small Logo" className="footer-logo" />
          <p className="brand-statement">
            Powering innovation with solar energy.
          </p>
        </div>
        <div className="footer-links">
        <div className="link-column">
          <h4>Contact</h4>
          <a href="mailto:solarcar@ualberta.ca">Contact Us</a>
        </div>

          <div className="link-column">
            <h4>Follow Us</h4>
            <div className="social-media">
              <a href="https://www.instagram.com/uasolarcar/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/in/uasolarcar/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} UAlberta Solar Car Club. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
