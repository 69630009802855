import React, { useState, useEffect } from 'react';
import '../css/about.css';
import carBackground from '../Assets/parallax.png'; 

function About() {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div className="about-background" style={{ backgroundPosition: `center ${offsetY * 0.6}px` }}>
      <div className="about-container">
        <section className="about-section box">
          <h2>About The Club</h2>
          <p>At the University of Alberta Solar Car Club, our passion for innovation sparks revolutionary ideas. We are a diverse team of thinkers, creators, and leaders dedicated to the pursuit of excellence in sustainable automotive technology. Our goal is to design and build state-of-the-art solar-powered vehicles that compete internationally, showcasing our commitment to a cleaner environment and our prowess in engineering and technology.</p>
          <p>We believe that educational outreach and community involvement are pivotal. Through workshops, seminars, and participatory events, we engage with the public and our peers to share knowledge and ignite a widespread passion for renewable energy solutions.</p>
        </section>
        <section className="about-section box">
          <h2>Our Mission</h2>
          <p>Our mission is to be at the forefront of sustainable technology, utilizing solar power to propel the future of transportation. We strive to challenge the status quo by integrating cutting-edge technology with environmental stewardship. By doing so, we aim to inspire a new generation of innovators who will continue to push the boundaries of what's possible, driving us towards a greener, more sustainable future.</p>
          <p>Join us on this exhilarating journey as we harness the power of the sun to fuel our aspirations and drive forward the frontier of sustainable mobility.</p>
        </section>
      </div>
    </div>
  );
}

export default About;
