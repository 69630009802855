

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Altair from '../Assets/AltairLogo.png';
import Ansys from '../Assets/ANSYSLOGO.png';
import eWorx from '../Assets/eWorx_Logo_black.png';
import Solidworks from '../Assets/SolidworksLogo.png';
import Eng from '../Assets/eng.png';

const useStyles = makeStyles((theme) => ({
    sponsorContainer: {
        padding: theme.spacing(2),
    },
    sponsorMessage: {
        marginBottom: theme.spacing(2),
    },
    sponsorLogo: {
        maxWidth: '100%',
        height: 'auto',
    },
}));

const Sponsor = () => {
    const classes = useStyles();

    return (
        <div className={classes.sponsorContainer}>
            <div className={classes.sponsorMessage}>
                <h2>Our Sponsors</h2>
                <p>Thank you to our sponsors for supporting our team.</p>
            </div>
            <Grid container spacing={2} className={classes.sponsorLogos} justify="center">
                <Grid item xs={6} sm={3}>
                    <img src={Eng} alt="Eng" className={classes.sponsorLogo} />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.sponsorLogos} justify="center">
                <Grid item xs={6} sm={3}>
                    <img src={Altair} alt="Altair" className={classes.sponsorLogo} />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <img src={Ansys} alt="Ansys" className={classes.sponsorLogo} />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.sponsorLogos} justify="center">
                <Grid item xs={6} sm={3}>
                    <img src={eWorx} alt="eWorx" className={classes.sponsorLogo} />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <img src={Solidworks} alt="Solidworks" className={classes.sponsorLogo} />
                </Grid>
            </Grid>

        </div>
    );
};

export default Sponsor;
