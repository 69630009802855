import React from 'react';
import '../css/main.css';
import logo from '../Assets/Wordmark.png'; 

function Main() {
  return (
    <div className="main-section">
      <div className="overlay"></div> 
      <div className="content-container">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="main-logo" />
        </div>
        <div className="main-content">
          <div className="slogan-content">
            <h3 style={{display:'inline'}}>BIG DREAM SMALL TEAM THINK GREEN</h3>
          </div>
          <p>Innovating for a brighter, sustainable tomorrow.</p>
        </div>
      </div>
    </div>
  );
}

export default Main;
